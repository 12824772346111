import {
    ssGetState,
    ssDispatch,
} from '~SpectateStore';
import {
    setClientState,
    setClientStateBatch,
} from '~SpectateStore/slices/clientStateSlice';
import { selectByKey } from '~SpectateStore/selectors/clientStateSelectors';


/**
 * Gets a value from bbClientState
 * @param  {String} key the for the value
 * @return {*} the value stored in bbClientState
 */
export const getState = (key) => {
    const data = selectByKey(ssGetState(), key);

    return data;
};

/**
 * Sets a value to bbClientState
 * @param  {String} key the for the value
 * @param  {*} val the value for the key
 * @return {void}
 */
export const setState = (key, val) => {
    return ssDispatch(
        setClientState({key, val})
    );
};

export const setStateBatch = (data) => {
    return ssDispatch(
        setClientStateBatch(data)
    );
};

