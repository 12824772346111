import {
    CashoutWsInterface,
    GeneralWsInterface,
} from './interface/WsInterface';


class WebSocketsManager {
    #config;

    #cashoutWsInterface;
    #generalWsInterface;

    setup(config) {
        this.#config = config;
    }

    debug(wsInterface) {
        switch(wsInterface) {
            case 'cashout':
                return this.#cashoutWsInterface;
            case 'general':
                return this.#generalWsInterface;
            default:
                throw new Error(`There is no ${wsInterface} Websocket interface`);
        }
    }

    get cashout() {
        if (!this.#cashoutWsInterface) {
            this.#cashoutWsInterface = new CashoutWsInterface(
                this.#config.cashout
            );
        }

        this.#cashoutWsInterface.connect();

        return this.#cashoutWsInterface;
    }

    get general() {
        if (!this.#generalWsInterface) {
            this.#generalWsInterface = new GeneralWsInterface(
                this.#config.general
            );
        }

        this.#generalWsInterface.connect();

        return this.#generalWsInterface;
    }

    destroyAll() {
        this.destroy('cashout');
        this.destroy('general');
    }

    destroy(wsInterface) {
        switch(wsInterface) {
            case 'cashout':
                this.#cashoutWsInterface?.kill();
                this.#cashoutWsInterface = null;

                return;
            case 'general':
                this.#generalWsInterface?.kill();
                this.#generalWsInterface = null;

                return;
            default:
                throw new Error(`There is no ${wsInterface} Websocket interface`);
        }
    }
}

const wsManager = new WebSocketsManager();

export { wsManager };
