import { WsBaseInterface } from './WsBaseInterface';

import { CallbacksManager } from '~spa/Utils/CallbacksManager';
import webSocketSubscriptionStore from '../utils/WsSubscriptionStore';
import { getRegulationTypeId, getLanguageCode } from '~shared/utils/user';

const {
    subscribe: subscribeToStore,
    unsubscribe: unsubscribeFromStore,
    getRegistrationPayload,
    clearSubscriptionStores,
} = webSocketSubscriptionStore;

class WsInterface extends WsBaseInterface {
    #msgCallbacks = new Map();
    #killCallbacks = new CallbacksManager();

    constructor(config) {
        super(config);
        this.addOnMessageCallback(
            (msg) => this.messageReceivedHandler(msg)
        );
    }

    messageReceivedHandler(message) {
        const { action } = message;

        this.#msgCallbacks.get(action)?.runAllCallbacks(message);
    }

    onMessage(action, callback) {
        if (!this.#msgCallbacks.has(action)) {
            this.#msgCallbacks.set(action, new CallbacksManager());
        }

        return this.#msgCallbacks.get(action).addCallback(callback);
    }

    onConnection(callback) {
        this.addOnOpenCallback(callback);
    }

    onConnectionClose(callback) {
        this.addOnCloseCallback(callback);
    }

    onKill(callback) {
        this.#killCallbacks.addCallback(callback);
    }

    kill() {
        this.#msgCallbacks.clear();
        this.#killCallbacks.runAllCallbacks();
        this.offConnectionCallbacks();
        this.disconnect();
    }
}

export class GeneralWsInterface extends WsInterface {
    constructor(config) {
        super(config);

        this.onConnection(() => {
            const payload = getRegistrationPayload();
            if (payload.length) {
                this.register(payload);
            }
        });

        this.onKill(clearSubscriptionStores);
    }

    subscribe(subscriptionData) {
        const { registerPayload } = subscribeToStore(
            subscriptionData
        );

        if (registerPayload.length) {
            this.register(registerPayload);
        }
    }

    unsubscribe(unsubscribeData) {
        const { registerPayload, unregisterPayload } = unsubscribeFromStore(
            unsubscribeData
        );

        if (unregisterPayload.length) {
            this.unregister(unregisterPayload);
        }

        if (registerPayload.length) {
            this.register(registerPayload);
        }
    }

    register(data) {
        const payload = {
            action: 'register',
            data,
            lang: getLanguageCode(),
            regulation_type_id: getRegulationTypeId(),
        };

        this.send(payload);
    }

    unregister(data) {
        this.send({
            action: 'unregister',
            data,
        });
    }
}

export class CashoutWsInterface extends WsInterface {}
