import { get } from 'object-path';
import { subscribers } from '../subscribers/subscribers';
import isEqual from 'react-fast-compare';

export const subscribeMiddleware = (store) => (next) => (action) => {

    let path = null;

    switch (action.type) {
        case 'user/setOddsFormat': {
            path = 'user.oddsFormat';
            break;
        }
        case 'user/setFavouriteSports': {
            path = 'user.favouriteSports';
            break;
        }
        case 'user/setThemeMode': {
            path = 'user.themeMode';
            break;
        }
        case 'user/setUserMode': {
            path = 'user.userMode';
            break;
        }
        default:
            break;
    }

    if (path) {
        const prevState = store.getState();
        const result = next(action);

        if (subscribers.hasOwnProperty(path)) {
            const nextState = store.getState();
            const prevValue = get(prevState, path);
            const nextValue = get(nextState, path);
            if (!isEqual(prevValue, nextValue)) {
                subscribers[path].forEach(cb => cb(prevValue, nextValue));
            }
        }

        return result;
    }

    return next(action);
};
