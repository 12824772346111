import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { moreGamesUpdate } from '~SpectateStore/actions/moreGamesActions';
import { widgetDataUpdate } from '~SpectateStore/actions/widgetDataActions';

export const tournamentsAdapter = createEntityAdapter();

const tournamentsSlice = createSlice({
    name: 'tournaments',
    initialState: tournamentsAdapter.getInitialState(),
    reducers: {
        addTournament: tournamentsAdapter.addOne,
        removeTournament: tournamentsAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload : { tournament } }) => {
                    if (Object.keys(tournament).length > 0) {
                        return tournamentsAdapter.upsertOne(state, tournament);
                    }

                    return state;
                },
            )
            .addCase(
                moreGamesUpdate,
                (state, { payload : { tournaments } }) => {
                    const tournament = Object.values(tournaments || [])[0];

                    if (tournament) {
                        tournamentsAdapter.upsertOne(state, tournament);
                    }
                },
            )
            .addCase(
                widgetDataUpdate,
                (state, { payload : { tournaments = {} } }) => {
                    if (Object.keys(tournaments).length > 0) {
                        return tournamentsAdapter.upsertMany(state, tournaments);
                    }

                    return state;
                },
            );
    },
});

export const { addTournament, removeTournament } = tournamentsSlice.actions;
export default tournamentsSlice.reducer;
