import {
    createSelector,
} from '@reduxjs/toolkit';

import pick from 'lodash/pick';

const PARLAY_TAB_MIN_SELECTIONS = 2;

const FETCH_FIELDS_ONLY = [
    'id', 'is_scorecast', 'is_cast_market', 'is_any',
];

export const selectUserSelections = state => state.betslip.userSelections;

export const selectUserSelectionIds = createSelector(
    selectUserSelections,
    selections => selections.map(({ id }) => id),
);

const selectEmptyStakeSelectionIds = createSelector(
    selectUserSelections,
    selections => selections.reduce(
        (acc, { stake, id, free_bet_id }) => {
            return (Number(stake) === 0 && !free_bet_id) ? [...acc, id] : acc;
        },
        [],
    ),
);

export const selectEmptyStakeSelections = createSelector(
    [
        selectEmptyStakeSelectionIds,
        selectUserSelections,
    ],
    (emptySingles, selections) => {
        const emptySinglesFlags = [];
        emptySingles.forEach(singleId => {
            const currentSelection = selections.find(selection => selection.id === singleId);
            if (currentSelection) {
                const {
                    is_cast_market,
                    is_any,
                    is_scorecast,
                } = currentSelection;

               emptySinglesFlags.push({
                    id: singleId,
                    is_cast_market,
                    is_any,
                    is_scorecast,
                });
            }
        });

        return {
            emptySingles,
            emptySinglesFlags,
        };
    }
);

export const selectAnySelectionIsCastMarket = createSelector(
    [
        selectUserSelections,
    ],
    (userSelections) => {
        return Object.values(userSelections).some(userSelection => {
            return userSelection.is_cast_market;
        });
    }
);

export const selectAnySelectionReverseCast = createSelector(
    [
        selectUserSelections,
    ],
    (userSelections) => {
        return Object.values(userSelections).some(userSelection => {
            return userSelection.is_any;
        });
    }
);

export const selectUserSelectionsDataForFetch = createSelector(
    selectUserSelections,
    selections => selections.reduce(
        (acc, curr) => [...acc, pick(curr, FETCH_FIELDS_ONLY)],
        []
    )
);

export const selectCountUserSelections = createSelector(
    selectUserSelections,
    (selections) => (selections.length),
);

export const selectUserSelectionById = createSelector(
    [
        selectUserSelections,
        (_state, selectionId) => (selectionId),
    ],
    (userSelections, selectionId) => (userSelections.find(selection => selection.id === selectionId))
);

export const selectIsParlayTabAvailable = (state) => {
    return selectUserSelections(state).length >= PARLAY_TAB_MIN_SELECTIONS;
};

export const selectUserHasAnyCastSelection = createSelector(
    selectUserSelections,
    userSelections => userSelections.some(
        userSelection => userSelection.is_cast_market === true
            || userSelection.is_scorecast === true
    )
);

const singlesMatch = (currentUserSelection, idx, traversedUserSelection) => {
    const {
        stake: currentStake,
        each_way_selected: currentEwSelected,
        free_bet_id: currentFbId,
    } = currentUserSelection;
    const [{
        stake: traversedStake,
        each_way_selected: traversedEwSelected,
        free_bet_id: traversedFbId,
    }] = traversedUserSelection;

    return currentStake === traversedStake
        && currentEwSelected === traversedEwSelected
        && currentFbId === traversedFbId;
};

const selectSinglesMatch = createSelector(
    selectUserSelections,
    userSelections => {
        if (userSelections.length < 1) {
            return false;
        }

        return userSelections.every(singlesMatch);
    },
);

export const selectSinglesStake = createSelector(
    [
        selectUserSelections,
        selectSinglesMatch,
    ],
    (userSelections, singlesStakeMatch) => {
        return singlesStakeMatch ? userSelections[0].stake : 0;
    }
);

export const selectSinglesEwSelected = createSelector(
    [
        selectUserSelections,
        selectSinglesMatch,
    ],
    (userSelections, singlesStakeMatch) => {
        return singlesStakeMatch ? userSelections[0].each_way_selected : false;
    }
);

export const selectHasAnySpSelected = createSelector(
    selectUserSelections,
    userSelections => {
        return Object.values(userSelections).some(userSelection => userSelection.sp_selected);
    },
);

export const selectCastMarketSelectionIds = createSelector(
    selectUserSelections,
    userSelections => userSelections
        .filter(({ is_cast_market }) => is_cast_market)
        .map(({ id }) => id.split('_')),
);

export const selectCastOrScorecastSelections = createSelector(
    selectUserSelections,
    userSelections => userSelections
        .filter(({ is_cast_market, is_scorecast }) => (is_cast_market || is_scorecast)),
);

export const selectIsSelectionInBetslip = createSelector(
    [
        selectUserSelections,
        (state, selectionId) => selectionId,
        (state, selectionId, isMultiBet) => isMultiBet,
    ],
    (userSelections, selectionId, isMultiBet) => {
        const ids = isMultiBet ? selectionId.split('_') : [selectionId];

        return ids.every(id => userSelections.find(selection => selection.id === id));
    }
);

export const selectCountAnyStake = createSelector(
    selectUserSelections,
    userSelections => userSelections
        .filter(userSelection => (Number(userSelection.stake)) > 0).length,
);
