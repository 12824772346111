import { getLanguageIso } from '~shared/utils/user';
import { getPageCorrelationId, getVersionAndBuildNumber } from '~spa/Utils/Spectate';
import { sendEventForClickStream } from '~spa/Utils/WrapperIntegration/Ucf';

const CLICKSTREAM_ATTRIBUTE_NAME = 'data-spectate-product';
export const EVENT_TYPE_CLICK = 'SPEC_SPORT_CLICK';
const EVENT_TYPE_PAGE_VIEW = 'SPEC_SPORT_PAGE_VIEW';

/**
 * Util to convert string to clickstream event obj
 * @param {string} eventProps the string containing the event props separated by semicolons (e.g. 'new-betslip;tabs;my-bets')
 * @returns {object} the eventData
 */
const convertStringToEventDataObj = (eventProps) => {
    const [
        eventCategory = null,
        eventAction = null,
        eventLabel = null,
    ] = eventProps.split(';');

    const eventData = {
        eventCategory,
        eventAction,
        eventLabel,
    };

    return eventData;
};

 /**
 * Create clickstream data to be added into the data layer
 *
 * @param {String} eventType the type of the event. eg.: pageView, click
 * @param {object|string} eventData the custom data of the event.
 *  If it is a string, the event props must be separated by semicolons (e.g. 'new-betslip;tabs;my-bets')
 * @returns {object} object that constains all formatted data
 */
const buildClickstreamData = (eventType, eventData) => {
    const { versionNumber, buildNumber } = getVersionAndBuildNumber();
    const correlationId = getPageCorrelationId();
    const language = getLanguageIso();

    const formattedEventData = typeof eventData === 'string'
        ? convertStringToEventDataObj(eventData)
        : eventData;

    const clickstreamData = {
        event: {
            message: eventType,
            event: eventType,
            innerType: 'Analytics',
            eventNameOverride: eventType,
            eventTypeOverride: ['Log', 'Analytics'],
            data: {
                ...formattedEventData,
                correlationId,
                language,
                buildNumber,
                versionNumber,
            },
        },
        target: 'spec-sport',
    };

    return clickstreamData;
};

/**
 * Takes the event type/data, formats the data, publishes into UC
 * @param {String} eventType the type of the event. eg.: SPEC_SPORT_CLICK, SPEC_SPORT_PAGE_VIEW
 * @param {object} eventData the custom data of the event
 * @returns {void}
 */
export const publishClickstreamDataToUC = (eventType, eventData) => {
    const clickstreamData = buildClickstreamData(eventType, eventData);

    // UC publish function
    sendEventForClickStream(clickstreamData);
};

/**
 * Handler for Clickstream data on every route change
 * @param {object} urlContextData the custom data of the event
 * @returns {void}
 */
export const pushToClickstreamByPageViewEvent = (urlContextData = {}) => {
    if (!Object.keys(urlContextData).length) {
        return;
    }

    const eventData = { ...urlContextData };
    delete eventData.originalUrl;
    publishClickstreamDataToUC(EVENT_TYPE_PAGE_VIEW, eventData);
};

/**
 * Handler for CLickstream data on every element click
 * @param {object} event the event data returned from the click event
 * @returns {void}
 */
const pushToClickstreamByClickEvent = ({ target }) => {
    const eventData = target.hasAttribute(CLICKSTREAM_ATTRIBUTE_NAME)
        ? target.getAttribute(CLICKSTREAM_ATTRIBUTE_NAME)
        : undefined;

    if (eventData) {
        publishClickstreamDataToUC(EVENT_TYPE_CLICK, eventData);
    }
};

/**
 * Initialize the Clickstream Data Layer
 * @returns {void}
 */
export const initializeClickstream = () => {
    document.addEventListener('click', pushToClickstreamByClickEvent, true);
};
