import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { moreGamesUpdate } from '~SpectateStore/actions/moreGamesActions';
import { widgetDataUpdate } from '~SpectateStore/actions/widgetDataActions';

export const sportsAdapter = createEntityAdapter();

const sports = createSlice({
    name: 'sports',
    initialState: sportsAdapter.getInitialState(),
    reducers: {
        addSport: sportsAdapter.addOne,
        removeSport: sportsAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload : { sport } }) => {
                    if (Object.keys(sport).length > 0) {
                        return sportsAdapter.upsertOne(state, sport);
                    }

                    return state;
                },
            )
            .addCase(
                moreGamesUpdate,
                (state, { payload : { sports } }) => {
                    const sport = Object.values(sports || [])[0];

                    if (sport) {
                        sportsAdapter.upsertOne(state, sport);
                    }
                },
            )
            .addCase(
                widgetDataUpdate,
                (state, { payload : { sports = {} } }) => {
                    if (Object.keys(sports).length > 0) {
                        return sportsAdapter.upsertMany(state, sports);
                    }

                    return state;
                },
            );
    },
});

export const { addSport, removeSport } = sports.actions;

export default sports.reducer;
