import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { eventUpdate } from '~SpectateStore/actions/eventUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';
import {
    moreGamesCleanUp,
    moreGamesUpdate,
} from '~SpectateStore/actions/moreGamesActions';
import {
    widgetDataUpdate,
    widgetDataCleanUp,
} from '~SpectateStore/actions/widgetDataActions';

export const marketsEventsAdapter = createEntityAdapter();

const marketsEventsSlice = createSlice({
    name: 'marketsEvents',
    initialState: marketsEventsAdapter.getInitialState(),
    reducers: {
        addMarketsEvents: marketsEventsAdapter.addMany,
        removeMarketsEvents: marketsEventsAdapter.removeMany,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                eventDataCleanUp,
                (state, { payload : { eventId } }) => {
                    Object.values(state.entities).forEach(marketEvent => {
                        if(Number(marketEvent.event_id) === Number(eventId)) {
                            marketsEventsAdapter.removeOne(state, marketEvent.id);
                        }
                    });
                },
            )
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload : { markets_events } }) => {
                    if (Object.keys(markets_events).length > 0) {
                        return marketsEventsAdapter.addMany(state, markets_events);
                    }

                    return state;
                },
            )
            .addCase(
                eventUpdate,
                (state, { payload : { marketsEvents } }) => {
                    if (marketsEvents.length >= 1) {
                        return marketsEventsAdapter.upsertMany(state, marketsEvents);
                    }

                    return state;
                },
            )
            .addCase(
                moreGamesUpdate,
                (state, { payload : { marketsEvents } }) => {
                    marketsEventsAdapter.addMany(state, marketsEvents);
                },
            )
            .addCase(
                moreGamesCleanUp,
                (state, { payload : { eventsIds } }) => {
                    (eventsIds || []).forEach(eventId => {
                        Object.values(state.entities).forEach(marketEvent => {
                            if (String(marketEvent.event_id) === String(eventId)) {
                                marketsEventsAdapter.removeOne(state, marketEvent.id);
                            }
                        });
                    });
                },
            )
            .addCase(
                widgetDataUpdate,
                (state, { payload : { marketsEvents = {} } }) => {
                    if (Object.keys(marketsEvents).length > 0) {
                        return marketsEventsAdapter.addMany(state, marketsEvents);
                    }

                    return state;
                },
            )
            .addCase(
                widgetDataCleanUp,
                (state, { payload: { selectionPointers } }) => {
                    if (!selectionPointers) {
                        return state;
                    }

                    const marketEventIds = selectionPointers.reduce((ids, {event_id, market_id}) => {
                        const marketEventId = `${event_id}-${market_id}`;

                        return [...ids, marketEventId];
                    }, []);

                    if (marketEventIds.length > 0) {
                        return marketsEventsAdapter.removeMany(state, marketEventIds);
                    }

                    return state;
                },
            );
    },
});

export const { addMarketsEvents, removeMarketsEvents } = marketsEventsSlice.actions;
export default marketsEventsSlice.reducer;
