import { v4 as uuidv4 } from 'uuid';

class CallbacksManager {
    #callbacks = new Map();

    addCallback(callback) {
        const uuid = uuidv4();

        this.#callbacks.set(uuid, callback);

        return {
            callbackId: uuid,
            off: () => this.removeCallbackById(uuid),
        };
    }

    removeCallbackById(uuid) {
        this.#callbacks.delete(uuid);
    }

    runAllCallbacks(data) {
        this.#callbacks.forEach(cb => cb(data));
    }

    offAll() {
        this.#callbacks.clear();
    }
}

export { CallbacksManager };
