import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';
import {
    widgetDataUpdate,
    widgetDataCleanUp,
} from '~SpectateStore/actions/widgetDataActions';

export const uiMarketsAdapter = createEntityAdapter();

const uiMarketsSlice = createSlice({
    name: 'uiMarkets',
    initialState: uiMarketsAdapter.getInitialState(),
    reducers: {
        addUiMarkets: uiMarketsAdapter.addMany,
        removeUiMarkets: uiMarketsAdapter.removeMany,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                eventDataCleanUp,
                (state, { payload : { eventId } }) => {
                    Object.values(state.entities).forEach(uiMarket => {
                        if(Number(uiMarket.event_id) === Number(eventId)) {
                            uiMarketsAdapter.removeOne(state, uiMarket.id);
                        }
                    });
                },
            )
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload : { uiMarkets } }) => {
                    if (Object.keys(uiMarkets).length > 0) {
                        return uiMarketsAdapter.addMany(state, uiMarkets);
                    }

                    return state;
                },
            )
            .addCase(
                widgetDataUpdate,
                (state, { payload : { uiMarkets = {} } }) => {
                    if (Object.keys(uiMarkets).length > 0) {
                        return uiMarketsAdapter.addMany(state, uiMarkets);
                    }

                    return state;
                },
            )
            .addCase(
                widgetDataCleanUp,
                (state, { payload: { selectionPointers } }) => {
                    if (!selectionPointers) {
                        return state;
                    }

                    const eventIds = selectionPointers.reduce((ids, { event_id }) => [...ids, event_id], []);
                    if (eventIds.length > 0) {
                        eventIds.forEach(eventId => {
                            Object.values(state.entities).forEach(uiMarket => {
                                if(Number(uiMarket.event_id) === Number(eventId)) {
                                    uiMarketsAdapter.removeOne(state, uiMarket.id);
                                }
                            });
                        });
                    }

                    return state;
                },
            );
    },
});

export const { addUiMarkets, removeUiMarkets } = uiMarketsSlice.actions;
export default uiMarketsSlice.reducer;
