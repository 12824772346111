/**
 * Retrieve the Websocket store and subscription store utilities.
 *
 * Uses two stores to manage subscriptions:
 *  - sportsbookEntities nested structure of Maps and Set
 *  - subscribedCounts flat Map of key, value pairs
 *
 * The wsInterface object uses subscribe() and unsubscribe() which returns filtered register and unregister
 * payloads to pass to Speakerbox. E.g. if we are already registered for updates at event level this includes
 * all markets and selections, so there is no need to send a registation message to subscribe at lower
 * levels.
 *
 * Internally, these utilities track subscriptions or "interests" at all levels. E.g. Two widgets are
 * registered for the same market level updates but we would only have sent one registration message to
 * speakerbox. Once the count of subscriptions or "interests" at that level reaches 0 we can send an unregister
 * message to Speakerbox (assuming that this does not interfere with subscriptions or "interests" other
 * components might have).
 *
 * When a call to unsubscribe(), and hence decrementSubscribedCount() causes a subscription count (or
 * "interest") at that level to reach 0, that function will return information about subscriptions at higher
 * and lower levels to allow wsInterface to decide wether or not to actually send an unregister message to
 * Speakerbox.
 *
 * NOTE: This utility only supports the main sicket, NOT betfeed or cashout websockets. DO NOT import
 * getWebSocketSubscriptionStore(), instead import the ready instantiated, default export "store".
 *
 * THIS UTILITY IS ONLY OF USE TO wsInterface.js, AND UNLESS YOU ARE WORKING THAT FILE, YOU SHOULD NOT NEED TO
 * TOUCH THIS. AND IF YOU DO AND DON'T UPDATE THE UNIT TESTS YOU WILL BE HUNTED DOWN.
 *
 * @returns {Object} of functions
 */
const getWebSocketSubscriptionStore = () => {
    /**
     * @var {Map} <eventId>: Map {
     *                <marketId>: Set {
     *                  ...selectionId
     *                }
     *            }
     * A heirarchical store of all entities we know about: events, markets and selections.
     * NB: This does NOT include subscriber counts - it is used to get (e.g.) all selections
     * belonging to a particular market or all markets for an event
     */
    const sportsbookEntities= new Map();

    /**
     * @var {Map} <subscriptionKey>: <subscribedCount>
     * subscriptionKey format depends on what level the subscription is for, i.e. event, market or selection:
     * <eventId>
     * <eventId>_<marketId>
     * <eventId>_<marketId>_<selectionId>
     */
    const subscribedCounts = new Map();

    /**
     * Get the store of sportsbook entities we know about
     * @returns {Map} the store
     */
    const getSportsbookEntitiesStore = () => sportsbookEntities;

    /**
     * Add an entity to the sportsbook entities store
     * @param {Number} eventId the id
     * @param {Number|null} marketId the id
     * @param {Number|null} selectionId the id
     * @returns {Void} naught
     */
    const addSubscribedId = (eventId, marketId, selectionId) => {
        const theEvents = getSportsbookEntitiesStore();

        if (!theEvents.has(eventId)) {
            theEvents.set(eventId, new Map());
        }
        if (marketId === null) {
            return;
        }

        const theMarkets = theEvents.get(eventId);
        if (!theMarkets.has(marketId)) {
            theMarkets.set(marketId, new Set());
        }
        if (selectionId === null) {
            return;
        }

        const theMarket = theMarkets.get(marketId);
        if (theMarket.has(selectionId)) {

            return;
        }

        theMarket.add(selectionId);
    };

    /**
     * Remove an entity from the sportsbook entities store
     * @param {Number} eventId the id
     * @param {Number|null} marketId the id
     * @param {Number|null} selectionId the id
     * @returns {Void} naught
     */
     const removeSubscribedId = (eventId, marketId, selectionId) => {
        const theEvents = getSportsbookEntitiesStore();
        if (theEvents.has(eventId) && marketId === null) {
            theEvents.delete(eventId);

            return;
        }

        const theMarkets = theEvents.get(eventId);
        if (theMarkets.has(marketId) && selectionId === null) {
            theMarkets.delete(marketId);

            return;
        }

        const theSelections = theMarkets.get(marketId);
        theSelections.delete(selectionId);
    };

    /**
     * Get the subscribed count store
     * @returns {Map} the store
     */
    const getSubscribedCountsStore = () => subscribedCounts;

    /**
     * Make a key for the subscribed counts store
     * @param {Number} eventId the id
     * @param {Number|null} marketId the id
     * @param {Number|null} selectionId the id
     * @returns {String} the key
     */
    const makeSubscribedCountsStoreKey = (eventId, marketId, selectionId) => {
        let storeKey = `${eventId}`;
        if (marketId !== null) {
            storeKey += `_${marketId}`;
            if (selectionId !== null) {
                storeKey += `_${selectionId}`;
            }
        }

        return storeKey;
    };

    /**
     * Check if we are getting updates for a particular level (or higher).
     * @param {Number} eventId the id
     * @param {Number|null} marketId the id
     * @param {Number|null} selectionId the id
     * @returns {Boolean} well? Are we?
     */
    const isReceivingUpdates = (eventId, marketId = null, selectionId = null) => {
        if (typeof eventId === 'undefined') {

            return false;
        }
        if (selectionId !== null && getSubscribedCount(makeSubscribedCountsStoreKey(eventId, marketId, selectionId))) {

            return true;
        }
        if (marketId !== null && getSubscribedCount(makeSubscribedCountsStoreKey(eventId, marketId, null))) {

            return true;
        }
        if (getSubscribedCount(makeSubscribedCountsStoreKey(eventId, null, null))) {

            return true;
        }

        return false;
    };

    /**
     * Retrieve a value from the subscribed count store
     * @param {String} key the store key
     * @returns {Number} the count of subscribers
     */
    const getSubscribedCount = (key) => {
        const count = getSubscribedCountsStore().get(key);
        if (typeof count === 'undefined') {

            return 0;
        }

        return count;
    };

    /**
     * Set a value
     * @param {String} key the key
     * @param {Number} value the value to set
     * @returns {Number} the new count
     */
    const setSubscribedCount = (key, value) => getSubscribedCountsStore().set(key, value);

    /**
     * Increment the "level of interest" or subscriber count for a particular level by updating
     * the subscribed count and sportsbook entities stores
     * @param {Number} eventId the id
     * @param {Number|null} marketId the id
     * @param {Number|null} selectionId the id
     * @returns {Number} the new count
     */
    const incrementSubscribedCount = (eventId, marketId = null, selectionId = null) => {
        if (typeof eventId === 'undefined') {

            return false;
        }

        const storeKey = makeSubscribedCountsStoreKey(eventId, marketId, selectionId);
        addSubscribedId(eventId, marketId, selectionId);
        const newCount = getSubscribedCount(storeKey) + 1;
        setSubscribedCount(storeKey, newCount);

        return newCount;
    };

    /**
     * Decrement the "level of interest" or subscriber count for a particular level by updating
     * the subscribed count and sportsbook entities stores.
     * Returns false if the level does not exist in the store or Object:
     *  {
     *      count: <the new count>,
     *      childSubscriptions: [] or Array of { event_id, market_id, selection_id }}, ** only if the new count is 0
     *      parentSubscriptionCount: null or <count of higher level subscriptions> ** only if the new count is 0
     *  }
     * @param {Number} eventId the id
     * @param {Number|null} marketId the id
     * @param {Number|null} selectionId the id
     * @returns {Object|false} see description
     */
    const decrementSubscribedCount = (eventId, marketId = null, selectionId = null) => {
        if (typeof eventId === 'undefined') {

            return false;
        }

        const storeKey = makeSubscribedCountsStoreKey(eventId, marketId, selectionId);
        if (!getSubscribedCountsStore().has(storeKey)) {
            /**
             * Note that we have multiple instances of event_manager which can cause duplicate messages to
             * arrive from speakerbox. In particular event_off and inplay_start messages will trigger
             * multiple unregistrations as we unsubscribe from the preplay event(s).
             */
            // eslint-disable-next-line no-console
            console.warn('decrementSubscribedCount() called on a non-existant store item');

            return false;
        }

        const subscribedCount = getSubscribedCount(storeKey);
        let childSubscriptions = [];
        let parentSubscriptionCount = null;
        if (subscribedCount <= 1) {
            if (subscribedCount < 1) {
                // eslint-disable-next-line no-console
                console.warn(`getSubscribedCount(${storeKey}) is ${subscribedCount} (expected 1)`);
            }

            // we grab the childsubscriptions here because they are needed in the calling code only if
            // the subscribed count reaches 0. If we do not we lose the information when we call
            // removeSubscribedId()
            // This logic removes the need for the calling code to get childSubscriptions every time it calls this function

            if (selectionId === null) {
                childSubscriptions = getChildSubscriptions(eventId, marketId);
            }

            if (!childSubscriptions.length) {
                removeSubscribedId(eventId, marketId, selectionId);
            }

            parentSubscriptionCount = getParentSubscriptionCount(eventId, marketId, selectionId);

            getSubscribedCountsStore().delete(storeKey);

            return { count: 0, childSubscriptions, parentSubscriptionCount };
        }

        setSubscribedCount(storeKey, subscribedCount - 1);
        const count = getSubscribedCount(storeKey);

        return { count, childSubscriptions, parentSubscriptionCount };
    };

    /**
     * Get an array of subscriptions at levels lower than the specidied level
     * Returns an array of what we would need to subscribe to Speakerbox if we were not subscribed
     * at this level. Note that this functionality is NOT used by Speakerbox at this time, but we need
     * the counts anyway and Array.length gives us that.
     * @param {Number} eventId the id
     * @param {Number|null} marketId the id
     * @returns {Array} of subscription information
     */
    const getChildSubscriptions = (eventId, marketId = null) => {
        const childSubscriptions = [];
        const theEvent = getSportsbookEntitiesStore().get(eventId);
        let marketsIterator;
        // only one market?
        if (marketId) {
            marketsIterator = [marketId];
        } else {
            marketsIterator = theEvent.keys();
        }

        for (const eventMarketId of marketsIterator) {
            // have we a subscriber for this market?
            const storeKey = makeSubscribedCountsStoreKey(eventId, eventMarketId, null);

            // only need to add markets if we are getting child subs of an event
            if (marketId === null && getSubscribedCount(storeKey)) {
                // we have a subscriber at market level - just return it.
                childSubscriptions.push({ event_id: eventId, market_id: eventMarketId });

                continue;
            }

            // else we need to grab the selections
            const theMarketSelections = theEvent.get(eventMarketId).values();
            for (const selectionId of theMarketSelections) {
                childSubscriptions.push({ event_id: eventId, market_id: eventMarketId, selection_id: selectionId });
            }
        }

        return childSubscriptions;
    };

    /**
     * Get the number of subscriptions at higher levels.
     * Note that it is meaningless to have marketId null, but this allows a accurate return rather than throwing an error
     * or validating params for a private function
     * @param {Number} eventId the id
     * @param {Number} marketId the id
     * @param {Number} selectionId the id
     * @returns {Number} the count
     */
    const getParentSubscriptionCount = (eventId, marketId = null, selectionId = null) => {
        let count = 0;
        if (selectionId !== null) {
            count += getSubscribedCount(makeSubscribedCountsStoreKey(eventId, marketId, null));
        }
        if (marketId !== null) {
            count += getSubscribedCount(makeSubscribedCountsStoreKey(eventId, null, null));
        }

        return count;
    };

    /**
     * Utility function to append a registration item to an existing speakerbox payload. It can be passed
     * directly to the websocket send() method as the 'data' parameter.
     * Function signature accounts for the fact this this is always called from within a loop.
     * Could be more elegant - it was written before we transpiled es6 in the legacy branch.
     * @param {Array} subscriptionsToSend an existing array of subscriptions
     * @param {Integer} event_id the id
     * @param {Integer} market_id the id
     * @param {Integer} selection_id the id
     * @returns {Array} updated array of subscription data
     */
    const addToSpeakerboxPayload = (subscriptionsToSend, event_id, market_id, selection_id) => {
        // subscribe for selections msg = {"action":"register","data":[{"event_id":4425376,"markets":{"814211":[1651546477]}}]}
        // subscribe for markets msg: {"action":"register","data":[{"event_id":4425376,"markets":{}]}

        // subscribe for selections msg = {"action":"register","data":[{"event_id":4425376,"markets":{"814211":[1651546477]}}]}
        // subscribe for a market msg: {"event_id":4425376,"markets":{"814211":[]}}]
        // subscribe for an event (with all markets) msg: {"action":"register","data":[{"event_id":4425376,"markets":{}]}
        const foundIndex = subscriptionsToSend.findIndex(function (eventObj) {
            return eventObj.event_id === event_id;
        });
        let eventSubObj;
        if (foundIndex !== -1) {
            eventSubObj = subscriptionsToSend[foundIndex];
        } else {
            eventSubObj = { 'event_id': event_id, 'markets': {} };
        }
        // market id to add?
        if (market_id && eventSubObj.markets[market_id] === undefined) {
            eventSubObj.markets[market_id] = [];
        }
        // append the selection
        if (selection_id && !eventSubObj.markets[market_id].includes(selection_id)) {
            eventSubObj.markets[market_id].push(selection_id);
        }

        // finally add it back to the array
        if(foundIndex !== -1) {
            subscriptionsToSend[foundIndex] = eventSubObj;
        } else {
            subscriptionsToSend.push(eventSubObj);
        }

        return subscriptionsToSend;
    };

    /**
     * Takes an array of subscription objects and filters it. Returns a payload that should be sent to
     * Speakerbox in order to receive the requested updates. Note that the returned payload might not
     * include any new registrations if we are already receiving updates for a particular level.
     * Example subscribePayload:
     * [
     *      { event_id: 442, market_id: 2, selection_id: 16515 },
     *      { event_id: 689, market_id: 1 },
     *      { event_id: 37 }
     * ]
     * Example return:
     * {
     *      registerPayload: [ ...speakerbox register payload... ]
     * }
     * @param {Array} subscribePayload array of subscription objects
     * @returns {Object} registration payload
     */
    const subscribe = (subscribePayload) => {
        /**
         * Speakerbox integration:
         * We want to subscribe for an id at level 'x' (x = event|market|selection)
         *  - if we are NOT getting updates due to a previous registration, send a registration message
         *  - always increase counter of subscribers to that id at level 'x' by one
         */
        let subscriptionsToSend = [];

        for (let i = 0; i < subscribePayload.length; i++) {
            const { event_id, market_id, selection_id } = subscribePayload[i];
            const eventId = parseInt(event_id, 10);
            const marketId = parseInt(market_id, 10) || null;
            const selectionId = parseInt(selection_id, 10) || null;

            if (!isReceivingUpdates(eventId, marketId, selectionId)) {
                subscriptionsToSend = addToSpeakerboxPayload(subscriptionsToSend, eventId, marketId, selectionId);
            }
            incrementSubscribedCount(eventId, marketId, selectionId); // returns new count if interested
        }

        return {
            registerPayload: subscriptionsToSend,
        };
    };

    /**
     * Takes an array of unsubscribe objects and filters it. Returns a payload that should be passed to
     * Speakerbox in order to maintain updates to components that have subscribed (registered an "interest")
     * to receive updates at a particular event, market, selection level.
     * If there is anything to unsubscribe from it is in unregisterPayload (in Speakerbox format)
     * Example unsubscribePayload:
     * [
     *      { event_id: 442, market_id: 2, selection_id: 16515 },
     *      { event_id: 689, market_id: 1 },
     *      { event_id: 37 }
     * ]
     * Example return:
     * {
     *      registerPayload: [ ...speakerbox register payload... ]
     *      unregisterPayload: [ ...speakerbox unregister payload... ]
     * }
     * @param {Array} unsubscribePayload array of unsubscribe objects
     * @returns {Object} registration and unregistration messages
     */
     const unsubscribe = (unsubscribePayload) => {
        /**
         * Speakerbox integrations:
         * We want to unsubscribe from an id at level 'x' (x = event|market|selection)
         *  - decrease counter of subscribers to id at level 'x' by one
         *  - if (counter is now 0) AND (there are subscriptions/interests at lower levels)
         *          send registrations for these lower levels
         *  - if (counter is now 0) AND (no subscriptions at lower or higher levels)
         *          send unregistration at level x
         * Note that registering at level x will OVERRIDE speakerbox registrations at upper levels,
         * e.g. register for (x, y, z) will override speakerbox regitrations at (x, y) and (x)
         */
        let subscriptionsToSend = [];
        let unsubscriptionsToSend = [];
        for (let i = 0; i < unsubscribePayload.length; i++) {
            const { event_id, market_id, selection_id } = unsubscribePayload[i];
            const eventId = parseInt(event_id, 10);
            const marketId = parseInt(market_id, 10) || null;
            const selectionId = parseInt(selection_id, 10) || null;
            const subscribedCount = decrementSubscribedCount(eventId, marketId, selectionId);

            if (!subscribedCount) {
                continue;
            }

            const { count: newSubscriberCount, childSubscriptions, parentSubscriptionCount } = subscribedCount;
            if (newSubscriberCount > 0) {

                continue;
            }

            if (childSubscriptions && childSubscriptions.length > 0) {
                // eslint-disable-next-line no-loop-func
                childSubscriptions.forEach(({
                    event_id: childEventId,
                    market_id: childMarketId = null,
                    selection_id: childSelectionId = null,
                }) => {
                    subscriptionsToSend = addToSpeakerboxPayload(subscriptionsToSend, childEventId, childMarketId, childSelectionId);
                });
            } else if (!parentSubscriptionCount) {
                unsubscriptionsToSend = addToSpeakerboxPayload(unsubscriptionsToSend, eventId, marketId, selectionId);
            }
        }

        return {
            registerPayload: subscriptionsToSend,
            unregisterPayload: unsubscriptionsToSend,
        };
    };

    /**
     * Produces registration payload based on current store state.
     * @return {Array} payload ready to be used for WS registration
     */
    const getRegistrationPayload = () => {
        const payload = {};

        getSubscribedCountsStore().forEach((_, key) => {
            const [event_id, market_id = null, selection_id = null] = key.split('_');

            if (!payload[event_id]) {
                payload[event_id] = {
                    event_id: Number(event_id),
                };
            }

            if (isReceivingUpdates(event_id)) {
                return;
            }

            if (!payload[event_id].markets) {
                payload[event_id].markets = {};
            }

            if (!payload[event_id].markets[market_id]) {
                payload[event_id].markets[market_id] = [];
            }

            if (isReceivingUpdates(event_id, market_id)) {
                return;
            }

            if (isReceivingUpdates(event_id, market_id, selection_id)) {
                payload[event_id].markets[market_id].push(Number(selection_id));
            }
        });

        return Object.values(payload);
    };

    /**
     * Clears the entities and the subscribed counts
     * @returns {Void} yup - still void
     */
    const clearSubscriptionStores = () => {
        sportsbookEntities.clear();
        subscribedCounts.clear();
    };

    return {
        subscribe,
        unsubscribe,
        getRegistrationPayload,
        clearSubscriptionStores,
    };
};

const store = getWebSocketSubscriptionStore();

export default store;
