import { calculateAmericanPrice } from '~shared/components/sport-event/utils';
import { getOrderingParams } from '~shared/utils/selection-ordering';

/**
 * Formats a list of events to put in Spectate Store.
 * Used in sports widgets and more events in preplay and inplay event pages.
 * Only generic sports widgets - golf still needs old format
 * @param {Object} eventsData list of events
 * @param {Array} selectionPointers for sports widgets with three market view
 * @returns {Object} {
 *  events,
 *  sports,
 *  categories,
 *  tournaments,
 *  markets,
 *  marketsEvents,
 *  selections,
 *  selectionsToBeRemoved,
 *  scoreboards,
 *  uiMarkets
 * }
 */
export const multipleEventsNormalizer = (eventsData, selectionPointers = []) => {
    const events = {};
    const sports = {};
    const categories = {};
    const tournaments = {};
    let markets = [];
    let marketsEvents = [];
    let selections = [];
    let selectionsToBeRemoved = [];
    const scoreboards = [];
    let uiMarkets = {};

    Object.values(eventsData).forEach(event => {
        const {
            sport_id,
            sport_name,
            sport_slug,
            sport_slug_i18n,
            sport_l10n_slug,
            category_id,
            category_name,
            category_slug,
            category_slug_i18n,
            category_l10n_slug,
            isUsFormat,
            tournament_id,
            tournament_name,
            tournament_slug,
            tournament_slug_i18n,
            tournament_l10n_slug,
            tournament_logos,
            id,
            name,
            slug,
            event_slug_i18n,
            event_l10n_slug,
            scheduled_date: scheduled_start,
            start_time: scheduled_start_time,
            match_status,
            competitors,
            is_streaming_available: isStreamingAvailable,
            inplay,
            extra_info = {},
            usesThreeMarketView,
            active = true, // Fallback needed for endpoints that used to only have betable but now have active
            tradable = true, // Fallback needed for endpoints that used to only have betable but now have tradable
            odds_boost_count,
            is_odds_boost,
            event_type,
            each_way_plus,
            racing_name,
        } = event;
        const eventId = String(id);
        const sportId = String(sport_id);
        const categoryId = String(category_id);
        const tournamentId = String(tournament_id);
        const eventExtraInfo = { ...extra_info };

        if (Object.keys(eventExtraInfo).length > 0) {
            delete eventExtraInfo.event_id;
            const scoreboard = {
                ...eventExtraInfo,
                id: eventId,
            };
            scoreboards.push(scoreboard);
        }

        const homeCompetitor = Object.values(competitors).find(
            competitor => competitor.is_home_team === true
        );
        const awayCompetitor = Object.values(competitors).find(
            competitor => competitor.is_home_team === false
        );

        if (usesThreeMarketView) {
            const gameLineId = `gameLineMarket-${eventId}`;
            uiMarkets[gameLineId] = uiMarketsNormalizer({
                selectionPointers,
                eventId,
                marketId: gameLineId,
                homeCompetitor,
                awayCompetitor,
                isUsFormat,
            });
        }

        const normalizedCompetitors = {
            ...(homeCompetitor !== undefined && { home: homeCompetitor }),
            ...(awayCompetitor !== undefined && { away: awayCompetitor }),
        };

        events[eventId] = {
            id: eventId,
            sport_id: sportId,
            category_id: categoryId,
            tournament_id: tournamentId,
            name,
            scheduled_start,
            scheduled_start_time,
            match_status,
            isStreamingAvailable,
            inplay,
            slug,
            translatedSlug: event_slug_i18n || event_l10n_slug || slug,
            active,
            tradable,
            is_odds_boost,
            event_type,
            /*
            Some widgets may not provide all the data keys expected in the response.
            To ensure compatibility and prevent errors, default values are conditionally assigned
            for keys like `each_way_plus` and `usesThreeMarketView` only if they are defined in the response.
            This approach avoids overwriting with undefined values and ensures that optional properties
            are included only when relevant.
            */
            ...(racing_name !== undefined && { racing_name }),
            ...(Object.keys(normalizedCompetitors).length !== 0 && { competitors: normalizedCompetitors }),
            ...(each_way_plus !== undefined && { each_way_plus }),
            ...(usesThreeMarketView !== undefined && { usesThreeMarketView }),
            ...(odds_boost_count !== undefined && { odds_boost_count: Number(odds_boost_count) }),
        };
        sports[sportId] = {
            id: sportId,
            name: sport_name,
            slug: sport_slug,
            translatedSlug: sport_slug_i18n || sport_l10n_slug || sport_slug,
        };
        categories[categoryId] = {
            id: categoryId,
            name: category_name,
            slug: category_slug,
            translatedSlug: category_slug_i18n || category_l10n_slug || category_slug,
            sport_id: sportId,
            ...(isUsFormat !== undefined && { isUsFormat }),
        };
        tournaments[tournamentId] = {
            id: tournamentId,
            name: tournament_name,
            slug: tournament_slug,
            translatedSlug: tournament_slug_i18n || tournament_l10n_slug || tournament_slug,
            sport_id: sportId,
            ...(tournament_logos !== undefined && { logos: tournament_logos }),
        };

        const {
            normalizedMarkets,
            normalizedMarketsEvents,
        } = marketsNormalizer(Object.values({...event.markets}), event.id);

        markets = markets.concat(normalizedMarkets);
        marketsEvents = marketsEvents.concat(normalizedMarketsEvents);

        const {
            normalizedSelections,
            selectionsToBeRemoved: removedSelections,
        } = selectionsNormalizer(Object.values({...event.markets}), event.id);

        selections = selections.concat(normalizedSelections);
        selectionsToBeRemoved = selectionsToBeRemoved.concat(removedSelections);
    });

    return {
        events,
        sports,
        categories,
        tournaments,
        markets,
        marketsEvents,
        selections,
        selectionsToBeRemoved,
        scoreboards,
        uiMarkets,
    };
};

// just for game lines market in sports widget display
const uiMarketsNormalizer = ({
        selectionPointers,
        eventId,
        marketId,
        homeCompetitor,
        awayCompetitor,
        isUsFormat,
    }) => {
    const market_ids = [];
    const lines = isUsFormat ? [awayCompetitor.name, homeCompetitor.name] : [homeCompetitor.name, awayCompetitor.name];
    selectionPointers.forEach(pointer => {
        if (!market_ids.includes(String(pointer.market_id))) {
            market_ids.push(String(pointer.market_id));
        }
    });

    return {
        slug: 'game_lines',
        id: marketId,
        event_id: eventId,
        market_ids,
        isUsFormat,
        lines,
    };
};

// returns the correct format for events in SpectateStore
// on event page, no new events will be pushed so only need items that will change
export const eventNormalizer = (event) => ({
    id: String(event.id),
    active: Boolean(event.active),
    tradable: Boolean(event.tradable),
});

// returns the correct format for markets and markets_events in SpectateStore
export const marketsNormalizer = (markets, eventId) => {
    const normalizedMarkets = [];
    const normalizedMarketsEvents = [];

    markets.forEach((market) => {
        const marketId = String(market.id);

        const normalizedMarket = {
            id: marketId,
            name: market.name,
            selection_schema: String(market.selection_schema),
            ...(market.restrict_cashout !== undefined && { restrict_cashout: market.restrict_cashout }),
            ...(market.group_names !== undefined && { group_names: market.group_names }),
        };

        const normalizedMarketEvent = {
            id: `${eventId}-${marketId}`,
            market_id: marketId,
            event_id: String(eventId),
            active: Boolean(market.active ?? true), // Fallback needed for endpoints that used to only have betable but now have active
            tradable: Boolean(market.tradable ?? true), // Fallback needed for endpoints that used to only have betable but now have tradable
            ...(market.boosted_odds !== undefined && { boosted_odds: Boolean(market.boosted_odds) }),
        };

        if (market.default_line_selections?.length > 0) {
            normalizedMarketEvent.default_line_selections = market.default_line_selections;
        }

        normalizedMarkets.push(normalizedMarket);
        normalizedMarketsEvents.push(normalizedMarketEvent);
    });

    return {
        normalizedMarkets,
        normalizedMarketsEvents,
    };
};

// returns the correct format for selections in SpectateStore
// and the selection ids that should be removed
export const selectionsNormalizer = (markets, eventId) => {
    const normalizedSelections = [];
    const selectionsToBeRemoved = [];

    markets.forEach((market) => {
        Object.keys(market.selections || []).forEach(selectionId => {
            const {
                name,
                price,
                fraction_price,
                decimal_price,
                sp_only,
                active = true, // Fallback needed for endpoints that used to only have betable but now have active
                tradable = true, // Fallback needed for endpoints that used to only have betable but now have tradable
                outcome,
                specialoddsvalue = null,
                selection_type_id = null,
                type,
                special_odds_value = null,
                boosted_odds,
                old_decimal_price,
                old_fraction_price,
            } = market.selections[selectionId];

            if (outcome !== 'UNSETTLED') {
                selectionsToBeRemoved.push(String(selectionId));

                return;
            }

            const normalizedSelection = {
                id: String(selectionId),
                name: name,
                event_id: String(eventId),
                market_id: String(market.id),
                decimal_price,
                fraction_price,
                american_price: calculateAmericanPrice(price),
                active,
                tradable,
                type,
                specialoddsvalue: special_odds_value || specialoddsvalue,
                sp_only,
                outcome,
                ordering_params : getOrderingParams(market.selections[selectionId], market.selection_schema),
                ...(boosted_odds !== undefined && { boosted_odds }),
                ...(selection_type_id !== undefined && { selection_type_id }),
                ...(old_decimal_price !== undefined && { old_decimal_price }),
                ...(old_fraction_price !== undefined && { old_fraction_price }),
            };

            normalizedSelections.push(normalizedSelection);
        });
    });

    return {
        normalizedSelections,
        selectionsToBeRemoved,
    };
};

export const formatWidgetData = (data, widgetId) => {
    if (!data) {
        return null;
    }

    const {
        widget_quick_links,
        event_order,
        events,
        selection_pointers,
    } = data;

    if (events.length === 0) {
        return {};
    }

    const normalizedData = multipleEventsNormalizer(events, selection_pointers);

    return {
        widgetData: {
            event_order,
            id: widgetId,
            widgetEventPointers: selection_pointers,
            widget_quick_links,
        },
        ...normalizedData,
    };
};
