import { noticeError } from '~spa/Utils/Newrelic';
import { CallbacksManager } from '~spa/Utils/CallbacksManager';

const DEFAULT_PING_OPTIONS = {
    interval: 3000,
    pongWait: 2000,
    maxLostPongs: 5,
};

const DEFAULT_RECONNECTION_DELAY_OPTIONS = {
    min: 3000,
    max: 15000,
    growFactor: 1.25,
    jitterMax: 1000,
};

const PING_PAYLOAD = {
    action: 'ping',
    data: null,
};

const isWsDebuggerEnabled = () => {
    // localStorage.setItem('websocketDebuggerEnabled', 'yes');
    // localStorage.removeItem('websocketDebuggerEnabled');
    return localStorage.getItem('websocketDebuggerEnabled') === 'yes';
};


export class WsBaseInterface {
    #url;
    #ws;
    #callbacks = {
        'open': new CallbacksManager(),
        'error': new CallbacksManager(),
        'close': new CallbacksManager(),
        'message': new CallbacksManager(),
    };
    #messegesQueue = [];
    #options;
    #pongTimeout;
    #keepAliveInterval;
    #missedPongs = 0;
    #shouldReconnectOnClose = true;
    #skipConnectionDelay = false;
    #retryCount = -1;

    constructor(config) {
        this.#setup(config);
    }

    #setup(config) {
        const { url, ping, reconnectionDelay } = config;

        this.#url = url;
        this.#options = {
            ping: {
                ...DEFAULT_PING_OPTIONS,
                ...ping,
            },
            reconnectionDelay: {
                ...DEFAULT_RECONNECTION_DELAY_OPTIONS,
                ...reconnectionDelay,
            },
            debuggerEnabled: isWsDebuggerEnabled(),
        };
    }

    get #readyState() {
        return this.#ws?.readyState ?? WebSocket.CLOSED;
    }

    #debug(level, msg, data = null) {
        if (!this.#options.debuggerEnabled) {
            return;
        }

        let bgColor;
        let color = '#fff';
        switch (level) {
            case 'ok':
                bgColor = '#12c99b';
                color = '#000';
                break;
            case 'error':
                bgColor = '#e41749';
                break;
            case 'warning':
                bgColor = '#f2a600';
                color = '#000';
                break;
            case 'info':
            default:
                bgColor = '#151a30';
                break;
        }

        // eslint-disable-next-line
        console.log(
            `%c[WS_DBG][${this.#url}] %c ${msg} `,
            'background: #000; color: #bada55',
            `background: ${bgColor}; color: ${color}`,
            data
        );
    }

    #resetRetryCount() {
        this.#retryCount = -1;
    }

    #getConnectionDelay() {
        if (this.#skipConnectionDelay) {
            this.#skipConnectionDelay = false;

            return 0;
        }

        const { min, max, growFactor, jitterMax } = this.#options.reconnectionDelay;
        const delay = min * Math.pow(growFactor, Math.max(0, this.#retryCount));

        if (delay > max) {
            return max;
        }

        const jitter = Math.random() * jitterMax;

        return Math.floor(delay + jitter);
    }

    #sendEnqueuedMessages() {
        const counter = this.#messegesQueue.length;
        if (counter < 1) {
            return;
        }

        this.#debug('info', `Sending ${counter} enqueued messages`);
        this.#messegesQueue.forEach(
            (msg) => this.send(msg)
        );
        this.#messegesQueue = [];
    }

    #setEventHandlers() {
        this.#ws.onopen = (e) => {
            this.#debug('info', 'onopen', e);
            this.#resetRetryCount();
            this.#sendEnqueuedMessages();
            this.#startKeepAlive();
            this.#runCallbacks('open', e);
        };
        this.#ws.onerror = (e) => {
            this.#debug('info', 'onerror', e);
            this.#runCallbacks('error', e);
        };
        this.#ws.onclose = (e) => {
            this.#debug('info', 'onclose', e);
            this.#stopKeepAlive();
            this.#runCallbacks('close', e);

            if (this.#shouldReconnectOnClose) {
                const delay = this.#getConnectionDelay();
                this.#debug('warning', 'Will reconnect in', delay);
                setTimeout(
                    () => this.connect(),
                    delay
                );
            }
        };
        this.#ws.onmessage = (e) => this.#handleMessage(e);
    }

    #handleMessage(e) {
        const msg = JSON.parse(e.data);
        this.#debug('info', 'onmessage', msg);

        if (msg.action === 'pong') {
            this.#handlePong();
        } else {
            this.#runCallbacks('message', msg);
        }
    }

    #runCallbacks(eventName, data) {
        this.#callbacks[eventName].runAllCallbacks(data);
    }

    #startKeepAlive() {
        this.#debug('info', 'Keep Alive -- START');
        this.#missedPongs = 0;
        this.#keepAliveInterval = setInterval(
            () => this.#sendPing(),
            this.#options.ping.interval
        );
    }

    #stopKeepAlive() {
        this.#debug('info', 'Keep Alive -- STOP');
        clearInterval(this.#keepAliveInterval);
    }

    #sendPing() {
        this.send(PING_PAYLOAD);
        this.#pongTimeout = setTimeout(
            () => this.#handleMissedPong(),
            this.#options.ping.pongWait
        );
    }

    #handleMissedPong() {
        this.#missedPongs++;
        this.#debug('warning', 'Missed Pong!', this.#missedPongs);

        if (this.#missedPongs <= this.#options.ping.maxLostPongs) {
            return;
        }

        this.#debug('error', 'Maximum number of missed PONGs exceeded. Trying to reconnect.');
        noticeError(
            new Error('[Spectate WS] Maximum number of missed PONGs exceeded. Trying to reconnect.'),
            this.#url
        );
        this.close();
    }

    #handlePong() {
        this.#debug('ok', 'Handle Pong');
        clearTimeout(this.#pongTimeout);
        this.#missedPongs = 0;
    }

    simulateReceive(message) {
        this.#handleMessage({ data: JSON.stringify(message) });
    }

    connect() {
        this.#debug('info', 'connection attempt', { readyState: this.#readyState });

        if (this.#readyState !== WebSocket.CLOSED) {
            return;
        }

        this.#debug('info', 'connecting', { readyState: this.#readyState });

        this.#retryCount++;

        try {
            this.#ws = new WebSocket(this.#url);
            this.#setEventHandlers();
            this.#shouldReconnectOnClose = true;
        } catch (e) {
            // eslint-disable-next-line
            console.error(e);
        }
    }

    close() {
        if (!this.#ws) {
            return;
        }

        if (this.#readyState === WebSocket.CLOSED) {
            return;
        }

        this.#stopKeepAlive();
        this.#ws.close();
    }

    reconnect() {
        if (this.#readyState === WebSocket.CLOSED) {
            this.connect();

            return;
        }

        this.#skipConnectionDelay = true;
        this.#shouldReconnectOnClose = true;
        this.close();
    }

    disconnect() {
        this.#shouldReconnectOnClose = false;
        this.close();
    }

    send(payload) {
        if (this.#readyState === WebSocket.OPEN) {
            const msg = JSON.stringify(payload);
            this.#ws.send(msg);
        } else {
            this.#messegesQueue.push(payload);
        }
    }

    addOnOpenCallback(cb) {
        return this.#callbacks['open'].addCallback(cb);
    }

    addOnErrorCallback(cb) {
        return this.#callbacks['error'].addCallback(cb);
    }

    addOnCloseCallback(cb) {
        return this.#callbacks['close'].addCallback(cb);
    }

    addOnMessageCallback(cb) {
        return this.#callbacks['message'].addCallback(cb);
    }

    offConnectionCallbacks() {
        this.#callbacks['open'].offAll();
        this.#callbacks['error'].offAll();
        this.#callbacks['close'].offAll();
    }
}
