import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { moreGamesUpdate } from '~SpectateStore/actions/moreGamesActions';
import { widgetDataUpdate } from '~SpectateStore/actions/widgetDataActions';

export const categoriesAdapter = createEntityAdapter();

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: categoriesAdapter.getInitialState(),
    reducers: {
        addCategory: categoriesAdapter.addOne,
        removeCategory: categoriesAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload: { category } }) => {
                    if (Object.keys(category).length > 0) {
                        return categoriesAdapter.upsertOne(state, category);
                    }

                    return state;
                },
            )
            .addCase(
                moreGamesUpdate,
                (state, { payload : { categories } }) => {
                    const category = Object.values(categories || [])[0];

                    if (category) {
                        categoriesAdapter.upsertOne(state, category);
                    }
                },
            )
            .addCase(
                widgetDataUpdate,
                (state, { payload : { categories = {} } }) => {
                    if (Object.keys(categories).length > 0) {
                        return categoriesAdapter.upsertMany(state, categories);
                    }

                    return state;
                },
            );
    },
});

export const { addCategory, removeCategory } = categoriesSlice.actions;
export default categoriesSlice.reducer;
